import styled from "styled-components";

export const LoginContainerStyled = styled.div`
  height: calc(100vh - 59px);
  .form_layout {
    margin-top: -30px;
    padding: 0 30px;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;
