import React from "react";
import { QrCodeContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";

class QrCodeContainer extends React.Component {
  state = {
    isLoading: true,
    perPage: 10,
  };

  handleHome = () => {
    this.props.router.navigate(ROUTE_PATH.EMPLOYEE_HOME);
  };

  render() {
    return (
      <QrCodeContainerStyled>
        <div className="QrCode_container">
          <div className="head_title">สแกนเพื่อ ลงเวลางาน</div>
          <div className="main_qr">
            <img
              className="qr_code"
              alt="Qr Code"
              src="/assets/images/Qrcode.png"
            />
          </div>
          <div className="card">
            <div className="main_title">สาขา โลตัสรามคำแหง</div>
          </div>
          <div className="footer_title">รหัสอ้างอิง: vrv56vr4</div>
        </div>
      </QrCodeContainerStyled>
    );
  }
}

export default withRouter(QrCodeContainer);
