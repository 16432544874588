import React, { useState, forwardRef } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { InputDatePickerBetweenStyled } from "./styled";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Icons } from "components";

const InputDatePickerBetween = ({
  theme_standard,
  label,
  startDatePlaceholder,
  endDatePlaceholder,
  onChange,
  // errors,
  disabled,
  // label,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_startDate, _setStartDate] = useState();
  const [_endDate, _setEndDate] = useState();

  const ExampleCustomInput1 = forwardRef(({ value, onClick }, ref) => (
    <div type="button" className="custom_date" onClick={onClick} ref={ref}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <div className="show_value" style={{ width: "100%" }}>
          {value ? `${moment(value).format("DD/MM/YYYY")}` : startDatePlaceholder}
        </div>
        <div>
          <Icons.ArrowDropdown transform="translate(13 11) rotate(180)" />
        </div>
      </div>
    </div>
  ));

  const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
    <div type="button" className="custom_date" onClick={onClick} ref={ref}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <div className="show_value" style={{ width: "100%" }}>
          {value ? `${moment(value).format("DD/MM/YYYY")}` : endDatePlaceholder}
        </div>
        <div>
          <Icons.ArrowDropdown transform="translate(13 11) rotate(180)" />
        </div>
      </div>
    </div>
  ));

  const _handleChangePicker = (key, value) => {
    switch (key) {
      case "startDate":
        _setStartDate(value);
        onChange && onChange({ startDate: value, endDate: _endDate });
        break;
      case "endDate":
        _setEndDate(value);
        onChange && onChange({ startDate: _startDate, endDate: value });
        break;
      default:
        break;
    }
  };

  return (
    <InputDatePickerBetweenStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <div className="date_between_container">
          <DatePicker
            className="date_picker"
            selected={_startDate}
            onChange={(e) => {
              _handleChangePicker("startDate", e);
            }}
            customInput={<ExampleCustomInput1 />}
          />

          <DatePicker
            className="date_picker"
            selected={_endDate}
            onChange={(e) => {
              _handleChangePicker("endDate", e);
            }}
            customInput={<ExampleCustomInput2 />}
          />
        </div>
      </div>
    </InputDatePickerBetweenStyled>
  );
};

InputDatePickerBetween.propTypes = {};

export default InputDatePickerBetween;
