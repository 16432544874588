import { useState } from "react";
import { BtnScanQRWidgetStyled } from "./styled";
// import QrReader from "react-qr-scanner";
import { QrReader } from "react-qr-reader";
import { AlertControl } from "components/Modals/Alert";
import { userService } from "apiServices";
import { Buttons, Displays, Modals, Icons } from "components";

const BtnScanQRWidget = ({ workLogType, label, onScanSuccess }) => {
  const [_isShowModal, _setIsShowModal] = useState(false);
  const [_isBgLoading, _setIsBgLoading] = useState(false);
  const [_locationCurrent, _setLocationCurrent] = useState();

  const _handleClickScan = () => {
    _setIsBgLoading(true);
    getLocation();
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          _setLocationCurrent({ latitude, longitude });
          _setIsBgLoading(false);
          setTimeout(() => {
            _setIsShowModal(true);
          }, 400);
        },
        (error) => {
          AlertControl.show({
            title: "ไม่สามารถใช้งานได้",
            description: "กรุณาเปิดแชร์ตำแหน่งที่ตั้งของคุณ",
            btnLabel1: "กลับ",
          });
        }
      );
    } else {
      _setIsBgLoading(false);
      AlertControl.show({
        title: "ไม่สามารถใช้งานได้",
        description: "กรุณาเปิดแชร์ตำแหน่งที่ตั้งของคุณ",
        btnLabel1: "กลับ",
      });
    }
  };

  const _handleCloseModal = () => {
    _setIsShowModal(false);
  };

  const _handleScan = async (e) => {
    _handleCloseModal();
    if (!e) {
      AlertControl.show({
        title: "แจ้งเตือนข้อผิดพลาด",
        description: "กรุณาสแกนใหม่อีกครั้ง",
        btnLabel1: "กลับ",
      });
    } else {
      AlertControl.show({
        title: "ยืนยันการลงเวลา",
        description: "คุณต้องการที่จะลงเวลาใช่หรือไม่",
        btnLabel1: "ใช่",
        onEvent1: async () => {
          _setIsBgLoading(true);
          let params = {
            shop_id: e.text,
            location: `${_locationCurrent.latitude},${_locationCurrent.longitude}`,
          };
          let res =
            workLogType === "1"
              ? await userService.POST_CHECKIN_WORK(params)
              : await userService.POST_CHECKOUT_WORK(params);
          if (res && res.status === 200) {
            // onScanSuccess && onScanSuccess(e.text);
            _setIsBgLoading(false);
            AlertControl.show({
              title: "สำเร็จ",
              description: "ท่านได้ทำการลงเวลางานสำเร็จ",
              btnLabel1: "ตกลง",
            });
          } else {
            _setIsBgLoading(false);
            AlertControl.show({
              title: "แจ้งเตือนข้อผิดพลาด",
              description: res.Message,
              btnLabel1: "กลับ",
            });
          }
        },
        btnLabel2: "ย้อนกลับ",
        onEvent2: () => _handleCloseModal(),
      });
    }
  };

  return (
    <BtnScanQRWidgetStyled>
      <Buttons.BtnIconPrefix
        theme_standard
        className="button"
        icon={<Icons.Camera />}
        label={label}
        onClick={_handleClickScan}
      />
      <Displays.BGLoading visible={_isBgLoading} />

      <Modals.BodyEmpty
        isShowModal={_isShowModal}
        onCloseModal={_handleCloseModal}
      >
        <div className="scan_show">
          <QrReader
            onResult={(result, error) => {
              if (!!result) {
                // setData(result?.text);
                _handleScan(result);
              }
              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
            constraints={{
              facingMode: "environment",
            }}
          />
          {/* <button
            type="button"
            onClick={() => _handleScan({ text: "654bb9b504daddb7f3e8edf8" })}
          >
            test location
          </button> */}
        </div>
      </Modals.BodyEmpty>
    </BtnScanQRWidgetStyled>
  );
};

export default BtnScanQRWidget;
