import React, { useState } from "react";
import { VerifyOTPFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Countdown from "react-countdown";
import { Inputs, Buttons, Icons } from "components";

const VerifyOTPForm = ({
  phoneNumber,
  onSubmit,
  onCancel,
  onClickResendOTP,
}) => {
  const schema = yup.object().shape({
    code: yup.string().required("Code required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [_resendBtnDisabled, _setResendBtnDisabled] = useState(true);

  const _onClickResendOTP = () => {
    _setResendBtnDisabled(true);
    onClickResendOTP && onClickResendOTP();
  };

  const _onComplete = () => {
    _setResendBtnDisabled(false);
  };

  return (
    <VerifyOTPFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="v_otp_title">{"Verify your identity"}</div>
        <div className="v_otp_sub_title">
          {"The verification code has been sent to"}
          {` ${phoneNumber}`}
        </div>
        <div className="v_otp_code_row">
          <div className="cr_left_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={"OTP code"}
                  errors={errors.code?.message}
                />
              )}
              name="code"
              defaultValue=""
            />
          </div>
          <div className="cr_right_col">
            <Buttons.BgIconPrefix
              icon={<Icons.Resend />}
              label={"Send again"}
              disabled={_resendBtnDisabled}
              onClick={_onClickResendOTP}
            />
          </div>
        </div>
        <div className="otp_detail">
          {_resendBtnDisabled && (
            <Countdown
              date={Date.now() + 60000}
              renderer={({ minutes, seconds }) => (
                <div>
                  0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </div>
              )}
              onComplete={_onComplete}
            />
          )}
          {"OTP code will be sent within 1 minutes"}
        </div>
        <div className="v_otp_action_row">
          <div className="v_otp_a_col">
            <Buttons.BgIconPrefix
              theme_cancel
              icon={<Icons.Cancel />}
              label={"Cancel"}
              onClick={onCancel}
            />
          </div>
          <div className="v_otp_a_col">
            <Buttons.BgIconPrefix
              type="submit"
              icon={<Icons.Confirm />}
              label={"Confirm"}
            />
          </div>
        </div>
      </form>
    </VerifyOTPFormStyled>
  );
};

VerifyOTPForm.propTypes = {};

export default VerifyOTPForm;
