export default function renderIcon({ right }) {
  if (right) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Icons_Chevron-left" data-name="Icons / Chevron-left" transform="translate(24 24) rotate(180)">
          <rect id="BG" width="24" height="24" rx="4" fill="rgba(255,255,255,0)" />
          <g id="chevron-left">
            <path id="Path_459" data-name="Path 459" d="M0,0H24V24H0Z" fill="none" />
            <path id="Path_460" data-name="Path 460" d="M15,6,9,12l6,6" fill="none" stroke="#7e84a3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          </g>
        </g>
      </svg>

    )
  } else {
    return (
      <svg id="Icons_Chevron-left" data-name="Icons / Chevron-left" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect id="BG" width="24" height="24" rx="4" fill="rgba(255,255,255,0)" />
        <g id="chevron-left">
          <path id="Path_459" data-name="Path 459" d="M0,0H24V24H0Z" fill="none" />
          <path id="Path_460" data-name="Path 460" d="M15,6,9,12l6,6" fill="none" stroke="#7e84a3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
      </svg>
    )
  }
}



