import BgStandard from "./BgStandard";
import BtnIconPrefix from "./BtnIconPrefix";
import BgIconPrefix from "./BgIconPrefix";

const EXPORT = {
  BgStandard,
  BtnIconPrefix,
  BgIconPrefix,
};

export default EXPORT;
