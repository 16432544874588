import { useState } from "react";
import { CustomerLayoutStyled } from "./styled";
import { Outlet } from "react-router-dom";
import { NavbarWidget } from "widgets";
import { Displays } from "components";

const CustomerLayout = () => {
  const [
    _isLoading,
    // _setIsLoading
  ] = useState(false);
  return (
    <CustomerLayoutStyled>
      <NavbarWidget />
      <div className="body_mainlayout">
        {_isLoading ? <Displays.Loading theme_standard_loading /> : <Outlet />}
      </div>
    </CustomerLayoutStyled>
  );
};

export default CustomerLayout;
