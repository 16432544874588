import styled from "styled-components";

export const InputDatePickerBetweenStyled = styled.div`
  .label {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .date_between_container {
    display: flex;
    margin: 0 -5px;
    .custom_date {
      margin: 0 5px;
      padding: 0 1rem;
      border-radius: 1rem;
      border: 0.0625rem solid ${({ theme }) => theme.COLORS.GRAY_1};
      height: 2.7rem;
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      color: ${({ theme }) => theme.COLORS.GRAY_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      .arrow_icon {
        margin-left: 5px;
        padding-top: 3px;
      }
    }
  }

  .date_picker_container {
    display: flex;
    align-items: center;
    column-gap: 12px;
    width: fit-content;
  }

  .theme_standard {
    .custom_date {
      flex-shrink: 0;
      padding: 10px 10px;
      border-radius: 8px;
    }
  }
`;
