import styled from "styled-components";

export const NavbarWidgetStyled = styled.div`
  margin: auto;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.COLORS.BLACK_1};
  .logo {
    width: 162px;
    height: auto;
  }
`;
