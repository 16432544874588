import { useEffect, useState } from "react";
import { PageLayoutStyled } from "./styled";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { setReduxAuthen } from "store/reducers/authenReducer";
import { NavbarWidget } from "widgets";
import { Displays } from "components";

const PageLayout = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const [_isLoading, _setIsLoading] = useState(true);

  useEffect(() => {
    _checkPermission();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _checkPermission = async () => {
    let token = localStorage.getItem("token");
    if (!token) {
      _destroyToken();
    } else {
      let res = await userService.POST_CHECK_TOKEN();
      if (res && res.status === 200) {
        _fetchProfile();
      } else {
        _destroyToken();
      }
    }
  };

  const _destroyToken = () => {
    _setIsLoading(false);
    localStorage.clear();
    navigate(ROUTE_PATH.LOGIN);
  };

  const _fetchProfile = async () => {
    let res = await userService.GET_MY_PROFILE();
    if (res && res.status === 200) {
      dispatch(setReduxAuthen(res.data));
      _setIsLoading(false);
    } else {
      _destroyToken();
    }
  };

  return (
    <PageLayoutStyled>
      <NavbarWidget />
      <div className="body_mainlayout">
        {_isLoading ? <Displays.Loading theme_standard_loading /> : <Outlet />}
      </div>
    </PageLayoutStyled>
  );
};

export default PageLayout;
