import styled from "styled-components";

export const SuccessCustomerContainerStyled = styled.div`
  min-height: 100vh;
  .Home_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;
    .card_container {
      padding-top: 50%;
      .card_wrap {
        margin: 10px;
        margin-bottom: 30px;
        background-color: white; /* สีพื้นหลังขาว */
        border-radius: 20px;
        border: 1px solid #707070;
        padding: 35px;
        display: flex;
        justify-content: center;
        .main_title {
          font-size: 20px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: center;
        }
      }
    }
  }
`;
