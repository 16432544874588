import styled from 'styled-components';

export const LoadingStyled = styled.div`
  .theme_standard_loading {
    width: 100%;
    height: ${({ heightLayout }) => heightLayout ? `calc(100vh - ${heightLayout})` : 'calc(100vh - 177px)'};
    display: flex;
    align-items: center;
    justify-content: center;
  }

`;
