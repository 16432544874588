import { store, persistor } from "store";
import Navigations from "navigations";
import theme from "styles/theme.json";
import { Provider } from "react-redux";
import { GlobalStyle } from "styles/GlobalStyle";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { Modals } from "components";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            <ToastContainer />
            <Navigations />
            <Modals.Alert />
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
