import styled from "styled-components";

export const ChangePasswordContainerStyled = styled.div`
  padding: 24px 12px 78px 12px;
  .button_home {
    position: absolute;
    bottom: 16px;
    left: 0px;
    right: 0px;
    margin: auto;
    max-width: 152px;
  }
`;
