import Color from "./Color";
import DateGroup from "./DateGroup";
import DateTime from "./DateTime";
import Dropdown from "./Dropdown";
import DropdownCheckbox from "./DropdownCheckbox";
import InputAutoSuggestion from "./InputAutoSuggestion";
import InputCheckBox from "./InputCheckBox";
import InputDatePickerBetween from "./InputDatePickerBetween";
import Radio from "./Radio";
import TextField from "./TextField";
import InputFilter from "./InputFilter";
import InputMultiSelect from "./InputMultiSelect";
import InputSwitch from "./InputSwitch";
import TextArea from "./TextArea";
import Upload from "./Upload";
import UploadImage from "./UploadImage";
import UploadSetImage from "./UploadSetImage";

const EXPORT = {
  Color,
  DateGroup,
  DateTime,
  Dropdown,
  DropdownCheckbox,
  InputFilter,
  InputMultiSelect,
  InputSwitch,
  InputCheckBox,
  InputAutoSuggestion,
  InputDatePickerBetween,
  Radio,
  TextArea,
  TextField,
  Upload,
  UploadImage,
  UploadSetImage,
};

export default EXPORT;
