import React from "react";
import { ChangePasswordFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const ChangePasswordForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    old_password: yup.string().required("กรุณากรอก"),
    new_password: yup
      .string()
      .required("กรุณากรอก")
      .min(8, "รหัสต้องมีอย่างน้อย 8 ตัว"),
    confirm_password: yup
      .string()
      .required("กรุณากรอก")
      .oneOf([yup.ref("new_password")], "รหัสผ่านไม่ตรงกัน"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <ChangePasswordFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label="รหัสผ่านเดิม"
                type="password"
                // placeholder={t('test')}
                errors={errors.old_password?.message}
              />
            )}
            name="old_password"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                type="password"
                label="รหัสผ่านใหม่"
                // placeholder={t('test')}
                errors={errors.new_password?.message}
              />
            )}
            name="new_password"
            defaultValue=""
          />
        </div>
        <div className="input_wrap mb_37">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                type="password"
                label="ยืนยันรหัสผ่านใหม่"
                // placeholder={t('test')}
                errors={errors.confirm_password?.message}
              />
            )}
            name="confirm_password"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard type="submit" label="ตกลง" />
      </form>
    </ChangePasswordFormStyled>
  );
};

ChangePasswordForm.propTypes = {};

export default ChangePasswordForm;
