import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { RadioStyled } from './styled';

const Radio = ({
  theme_standard,
  value,
  label,
  options,
  name,
  errors,
  onChange,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <RadioStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        {options &&
          options.map((e, i) => (
            <div key={i} className="radio_input_item">
              <div className="group_show_radio">
                <input
                  className="radio_input"
                  type="radio"
                  id={e.value}
                  name={name}
                  value={e.value}
                  onChange={onChange}
                  checked={e.value === value}
                />
                <label className="radio_label" htmlFor={e.value}>
                  {e.label}
                </label>
              </div>
              {e.subDetail && (
                <div className="radio_sub_detial">{e.subDetail}</div>
              )}
            </div>
          ))}
        {errors && <div className="errors">{errors}</div>}
      </div>
    </RadioStyled>
  );
};

Radio.propTypes = {};

export default Radio;
