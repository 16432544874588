import React from "react";
import { CusActivityContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import queryString from "query-string";
import { toast } from "react-toastify";
import { SuccessCustomerContainer } from "./Steps";
import { AlertControl } from "components/Modals/Alert";
import { VerifyOTP } from "./Modals";
import { Displays, Forms, Modals } from "components";

class CusActivityContainer extends React.Component {
  state = {
    isLoading: true,
    step: 1,
  };

  componentDidMount() {
    let { pc_id, pc_code } = queryString.parse(
      this.props.router.location.search
    );
    if (!pc_id || !pc_code) {
      this.handlePcNotfound();
    } else {
      this.getLocation();
      this.fetchData();
    }
  }

  fetchData = async () => {
    let { pc_id, pc_code } = queryString.parse(
      this.props.router.location.search
    );
    let resArr = await Promise.all([
      userService.GET_PRODUCT_LIST(`?pagination=false`),
      userService.GET_PC_DETAIL(pc_id),
      userService.POST_REGIS_TRANSACTION_VERIFY({ pc_code }),
    ]);
    if (resArr && resArr[2].status === 200) {
      this.setState({
        productOptions: resArr[0].data.docs.map((e) => ({
          label: e.name_th,
          value: e._id,
        })),
        data: resArr[1].data,
        isLoading: false,
      });
    } else {
      this.handlePcNotfound();
    }
  };

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          this.setState({
            currentLocation: { latitude, longitude },
          });
        },
        (error) => {
          AlertControl.show({
            title: "ไม่สามารถใช้งานได้",
            description: "กรุณาเปิดแชร์ตำแหน่งที่ตั้งของคุณ",
            btnLabel1: "กลับ",
          });
        }
      );
    } else {
      AlertControl.show({
        title: "ไม่สามารถใช้งานได้",
        description: "กรุณาเปิดแชร์ตำแหน่งที่ตั้งของคุณ",
        btnLabel1: "กลับ",
      });
    }
  };

  handlePcNotfound = () => {
    this.setState({
      data: false,
      isLoading: false,
    });
  };

  handleSuccess = () => {
    this.props.router.navigate(ROUTE_PATH.CUSTOMER_SUCCESS);
  };

  hideModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  handleSubmit = async (values) => {
    const { currentLocation } = this.state;
    // this.submit(values);
    if (!currentLocation) {
      AlertControl.show({
        title: "ไม่สามารถใช้งานได้",
        description: "กรุณาเปิดแชร์ตำแหน่งที่ตั้งของคุณ",
        btnLabel1: "กลับ",
      });
    } else {
      this.setState({
        renderModal: (
          <VerifyOTP
            phoneNumber={values?.cus_phone_number}
            onCancel={this.hideModal}
            onVerifySuccess={() => this.submit(values)}
          />
        ),
      });
    }
  };

  submit = async (values) => {
    const { currentLocation, data, productOptions } = this.state;
    this.setState({
      renderModal: false,
      isBgLoading: true,
    });
    let findProduct = productOptions.find(
      (e) => e.value === values.product_ref_id
    );
    let params = {
      ...values,
      pc_id: data._id,
      pc_code: data.employee_code,
      product_ref_id: findProduct.value,
      product_name: findProduct.label,
      shop_code: data.shop_ref_code,
      team_code: data.team_ref_code,
      department_code: data.department_ref_code,
      location: `${currentLocation.latitude},${currentLocation.longitude}`,
    };
    // if (
    //   !values?.image_file ||
    //   values?.image_file.filter((e) => e.isNewUpload).length === 0
    // ) {
    //   toast.error("กรุณาอัพโหลดสลิปใบเสร็จ");
    // } else {
    delete params.image_file;
    const bodyFormData = new FormData();
    Object.keys(params).forEach((key) => {
      bodyFormData.set(key, params[key]);
    });
    // bodyFormData.append("image", values?.image_file[0]);
    let resArr = await Promise.all([
      userService.POST_TRANSACTION_UPLOAD(bodyFormData),
    ]);
    if (resArr && resArr[0].status === 200) {
      this.setState({
        isBgLoading: false,
        intialValues: {
          cus_first_name: params.cus_first_name,
          cus_last_name: params.cus_last_name,
          cus_phone_number: params.cus_phone_number,
        },
      });
      toast.success("ลงทะเบียนสำเร็จ");
      this.handleNextStep();
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error(resArr[0].Message);
    }
    // }
  };

  handleNextStep = () => {
    this.setState({
      step: 2,
    });
  };

  handleRegisMore = () => {
    this.setState(
      {
        isLoading: true,
        step: 1,
      },
      () => this.fetchData()
    );
  };

  render() {
    const {
      isLoading,
      isBgLoading,
      intialValues,
      data,
      renderModal,
      step,
      productOptions,
    } = this.state;
    return (
      <CusActivityContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : !data ? (
          <div className="not_data_label">ข้อมูลพนักงานขายไม่ถูกต้อง</div>
        ) : step === 1 ? (
          <>
            <div className="head_title">ลงทะเบียนกิจกรรม</div>
            <Forms.CusActivityForm
              intialValues={intialValues}
              productOptions={productOptions}
              onSubmit={this.handleSubmit}
            />
          </>
        ) : (
          <SuccessCustomerContainer onRegisMore={this.handleRegisMore} />
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.hideModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </CusActivityContainerStyled>
    );
  }
}

export default withRouter(CusActivityContainer);
