import React from "react";
import { SuccessCustomerContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Buttons } from "components";

class SuccessCustomerContainer extends React.Component {
  state = {
    isLoading: true,
  };

  handleHome = () => {
    this.props.router.navigate(ROUTE_PATH.EMPLOYEE_HOME);
  };

  render() {
    const { onRegisMore } = this.props;
    return (
      <SuccessCustomerContainerStyled>
        <div className="Home_container">
          <div className="card_container">
            <div className="card_wrap">
              <div className="main_title">
                <div>ท่านตอบแบบสอบถามสำเร็จ</div>
                <div>ขอบคุณที่ร่วมตอบแบบสอบถาม</div>
              </div>
            </div>
            <div className="btn_wrap">
              <Buttons.BgStandard
                label="ลงทะเบียนเพิ่มเติม"
                onClick={onRegisMore}
              />
            </div>
          </div>
        </div>
      </SuccessCustomerContainerStyled>
    );
  }
}

export default withRouter(SuccessCustomerContainer);
