export default function MenuHome({ color = "#fff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34.829"
      height="27.891"
      viewBox="0 0 34.829 27.891"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_654"
            data-name="Rectangle 654"
            width="34.829"
            height="27.891"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_433" data-name="Group 433" clipPath="url(#clip-path)">
        <path
          id="Path_689"
          data-name="Path 689"
          d="M17.411,27.891q-6.924,0-13.848,0A3.527,3.527,0,0,1,.031,24.841,3.775,3.775,0,0,1,0,24.289Q0,16.557,0,8.825A3.518,3.518,0,0,1,3.615,5.206H7.538a1.468,1.468,0,0,0,1.518-1.1q.5-1.5,1-3A1.571,1.571,0,0,1,11.589,0q5.828,0,11.655,0a1.576,1.576,0,0,1,1.53,1.114q.506,1.509,1.007,3.018a1.462,1.462,0,0,0,1.491,1.073h3.947a3.516,3.516,0,0,1,3.608,3.6q0,7.732,0,15.464a3.519,3.519,0,0,1-3.614,3.619q-6.9,0-13.8,0M26.019,15.7a8.605,8.605,0,1,0-8.691,8.591A8.622,8.622,0,0,0,26.019,15.7m4.053-4.6a1.142,1.142,0,1,0-1.137-1.149A1.14,1.14,0,0,0,30.072,11.1"
          transform="translate(0 0)"
          fill={color}
        />
        <path
          id="Path_690"
          data-name="Path 690"
          d="M51.78,49.635a5.269,5.269,0,1,1,5.232,5.282,5.275,5.275,0,0,1-5.232-5.282"
          transform="translate(-39.636 -33.962)"
          fill={color}
        />
      </g>
    </svg>
  );
}
