import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputMultiSelectStyled } from './styled';
import Select from 'react-select';
import theme from 'styles/theme.json';

const InputMultiSelect = ({
  theme_standard,
  label,
  options,
  onChange,
  placeholder,
  isMulti,
  value,
  errors,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: `1px dotted ${theme.COLORS.GRAY_1}`,
      // color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      // width: 200,
      borderRadius: '1rem',
      border: `0.0625rem solid ${theme.COLORS.GRAY_1}`,
      minHeight: '2.65rem',
      display: 'flex',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  return (
    <InputMultiSelectStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <Select
          isMulti={isMulti}
          styles={customStyles}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          options={options}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </InputMultiSelectStyled>
  );
};

InputMultiSelect.propTypes = {};

export default InputMultiSelect;
