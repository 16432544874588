import styled from 'styled-components';

export const InputAutoSuggestionStyled = styled.div`
  .label {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  .react-autosuggest__input {
    border-radius: 1rem;
    border: 0.0625rem solid ${({ theme }) => theme.COLORS.GRAY_1};
    width: 100%;
    box-sizing: border-box;
    background: ${({ theme, disabled }) =>
      !disabled ? theme.COLORS.WHITE_1 : theme.COLORS.GRAY_4};
    padding: 0.3125rem 1.25rem;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    height: 2.75rem;
    &::placeholder {
      color: ${({ theme }) => theme.COLORS.BLUE_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    }
  }
  .react-autosuggest__suggestion {
    cursor: pointer;
  }

  .theme_standard {
  }
`;
