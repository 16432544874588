import styled from "styled-components";

export const CusActivityContainerStyled = styled.div`
  padding: 24px 20px 78px 20px;
  min-height: 100vh;
  .not_data_label {
    margin-top: 60px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
  }

  .head_title {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
  }
  .form_component {
    display: flex;
    justify-content: center;
  }
`;
