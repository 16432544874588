import styled from "styled-components";

export const BtnIconPrefixStyled = styled.div`
  .btn {
    border-radius: 23px;
    width: 100%;
    padding: 0.625rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    background: ${({ theme, disabled }) => (!disabled ? theme.COLORS.ORANGE_2 : theme.COLORS.GRAY_4)};
    color: ${({ theme, disabled }) => (disabled ? theme.COLORS.WHITE_1 : theme.COLORS.BLACK_2)};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_6};
    background: ${({ theme }) => theme.COLORS.BLUE_6};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    &:hover {
      background: #165ac8;
      transition: 0.7s;
    }
  }

  .theme_standard {
    .btn {
      border-radius: 20px;
      border: 1px solid ${({ theme }) => theme.COLORS.BLUE_6};
      border: ${({ disabled }) => disabled && "none"};
      height: 60px;
      padding: 0.625rem 1rem;
      width: 100%;
      background: ${({ theme, disabled }) => (!disabled ? theme.COLORS.BLUE_6 : theme.COLORS.GRAY_4)};
      color: ${({ theme, disabled }) => (disabled ? theme.COLORS.BLACK_2 : theme.COLORS.WHITE_1)};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      &:hover {
        background: ${({ disabled }) => !disabled && "#165ac8"};
        transition: 0.7s;
      }
    }
  }
`;
