import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { DropdownCheckboxStyled } from "./styled";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "react-dropdown/style.css";
import { Icons } from "components";

const DropdownCheckbox = ({ theme_standard, theme_normal, label, options, value, onChange, onChaneCustom, placeholder, errors, disabled }) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_normal: theme_normal,
  });
  const customStyle = {
    control: (base) => ({
      ...base,
      border: "0 !important",
      // This line disable the blue border
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
    }),
  };

  const animatedComponents = makeAnimated();

  return (
    <DropdownCheckboxStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <div className="input_dropdown">
          <Select
            styles={customStyle}
            components={animatedComponents}
            isMulti
            className="myPlaceholderClassName"
            closeMenuOnSelect={false}
            disabled={disabled}
            options={options}
            onChange={(e) => {
              onChange(e.value);
              onChaneCustom && onChaneCustom(e.value);
            }}
            value={value}
            placeholder={placeholder}
            arrowClosed={
              <div className="arrow">
                <Icons.ArrowDropdown color="#dcdcdc" width="13px" />
              </div>
            }
            arrowOpen={
              <div className="arrow">
                <Icons.ArrowDropdown translate="21.81" rotate="90" color="#dcdcdc" width="13px" />
              </div>
            }
          />
        </div>
      </div>
      {errors && <div className="errors">{errors}</div>}
    </DropdownCheckboxStyled>
  );
};

DropdownCheckbox.propTypes = {};

export default DropdownCheckbox;
