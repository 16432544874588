import styled from 'styled-components';

export const LabelValueStyled = styled.div`
  .label_value_container {
    display: flex;
    align-items: center;
    .lvc_left_col {
      width: ${({ leftWidth }) => (leftWidth ? leftWidth : '20%')};
      color: ${({ theme }) => theme.COLORS.BLUE_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    }
    .lvc_right_col {
      flex: 1;
      color: ${({ theme }) => theme.COLORS.BLUE_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    }
  }

  .theme_standard {
  }
`;
