import styled from 'styled-components';

export const TextAreaStyled = styled.div`
  .label {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  textarea {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border: 0.0625rem solid ${({ theme }) => theme.COLORS.GRAY_1};
    width: 100%;
    height: 122px;
    box-sizing: border-box;
    border-radius: 1rem;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 8px 9px;
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
