import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { TextAreaStyled } from './styled';

const TextArea = ({ theme_standard, label, errors, ...props }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <TextAreaStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <textarea {...props} />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </TextAreaStyled>
  );
};

TextArea.propTypes = {};

export default TextArea;
