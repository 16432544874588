import React from "react";
import { TimeWorkContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import queryString from "query-string";
import moment from "moment";
import { Displays, Tables, Inputs, Icons, Buttons } from "components";

class TimeWorkContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(
      `?pagination=false&start_date=${moment()
        .startOf("month")
        .format("YYYY-MM-DD")}&end_date=${moment()
        .endOf("month")
        .format("YYYY-MM-DD")}`
    );
  }

  fetchData = async (queryString) => {
    let res = await userService.GET_MY_WORK_LOG_LIST(queryString);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleFilterKeyword = (e) => {
    this.setState({
      isBgLoading: true,
    });
    this.fetchData(
      !e
        ? `?pagination=false&start_date=${moment()
            .startOf("month")
            .format("YYYY-MM-DD")}&end_date=${moment()
            .endOf("month")
            .format("YYYY-MM-DD")}`
        : `${e}&pagination=false`
    );
  };

  handleHome = () => {
    this.props.router.navigate(ROUTE_PATH.EMPLOYEE_HOME);
  };

  render() {
    const { router } = this.props;
    const { isBgLoading, isLoading, pagination, data } = this.state;
    return (
      <TimeWorkContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <div className="Timework_container">
            <div className="head_title">ดูเวลาเข้าออกงาน</div>
            <div className="Table_Container">
              <Inputs.InputFilter
                theme_standard_input_filter
                dataFilter={dataFilter(router.location?.search)}
                btnLabel="เลือก"
                onQueryFilter={this.handleFilterKeyword}
              />
              <div className="body_table">
                <Tables.Standard
                  columns={columns({
                    handleClickDetail: this.handleClickCreate,
                  })}
                  data={data?.docs}
                  showPagination
                  pagination={pagination}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            </div>
            <div className="button_home">
              <Buttons.BgStandard
                theme_menu_btn
                icon={<Icons.MenuHome />}
                label={"Home"}
                onClick={this.handleHome}
              />
            </div>
          </div>
        )}
      </TimeWorkContainerStyled>
    );
  }
}

const dataFilter = (query) => {
  let initialValues = queryString.parse(query);
  return [
    {
      accessor: "month",
      length: "130px",
      filter: {
        fieldQuery: "month",
        defaultValue: initialValues["month"],
        type: "dateMonth",
        placeholder: "เดือน",
        options: [],
      },
    },
  ];
};

const columns = () => [
  {
    Header: (
      <div style={{ textAlign: "left", minWidth: 110 }}>วันเวลาลงทะเบียน</div>
    ),
    accessor: "created_at",
    Cell: (props) => (
      <div>{moment(props.value).format("DD/MM/YYYY HH:mm")}</div>
    ),
  },
  {
    Header: (
      <div style={{ textAlign: "left", textWrap: "nowrap" }}>สถานที่ทำงาน</div>
    ),
    accessor: "store_name",
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left", minWidth: 90 }}>Check in</div>,
    accessor: "check_in_at",
    Cell: (props) => (
      <div>
        {!props.value ? "-" : moment(props.value).format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left", minWidth: 90 }}>Check out</div>,
    accessor: "check_out_at",
    Cell: (props) => (
      <div>
        {!props.value ? "-" : moment(props.value).format("DD/MM/YYYY HH:mm")}
      </div>
    ),
  },
];

export default withRouter(TimeWorkContainer);
