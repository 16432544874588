import React from "react";
import { DeniedContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { MdOutlineHome } from "react-icons/md";
import { Buttons } from "components";

class DeniedContainer extends React.Component {
  state = {
    isLoading: true,
  };

  handleHome = () => {
    this.props.router.navigate(ROUTE_PATH.EMPLOYEE_HOME);
  };

  render() {
    return (
      <DeniedContainerStyled>
        <div className="Home_container">
          <div className="card_container">
            <div className="card">
              <div className="main_title">
                <div>
                  <div>ท่านลงเวลางานไม่สำเร็จ</div>
                  <div>กรุณาเช็คโลเคชั่นของท่านว่า</div>
                  <div>อยู่ในบริเวณที่อนุญาติให้ลงเวลาหรือไม่</div>
                </div>
                <div>
                  <div>หากมีปัญหากรุณาติดต่อ </div>
                  <div>หัวหน้าหน่วยของท่าน</div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_home">
            <Buttons.BgStandard
              theme_stadard_btn
              icon={<MdOutlineHome className="icon" />}
              label={"Home"}
              onClick={this.handleHome}
            />
          </div>
        </div>
      </DeniedContainerStyled>
    );
  }
}

export default withRouter(DeniedContainer);
