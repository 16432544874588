import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AvatarStyled } from './styled';

const Avatar = ({ theme_standard }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AvatarStyled>
      <div className={customClass}>
        <div className="avatar"></div>
      </div>
    </AvatarStyled>
  );
};

Avatar.propTypes = {};

export default Avatar;
