import React from 'react';
// import PropTypes from 'prop-types'
import { LoadingStyled } from './styled';
import cx from 'classnames';
import * as Loader from "react-loader-spinner";

const Loading = ({
  theme_standard_loading,
  // type = 'Puff',
  color = '#216fed',
  color2 = "#abadaf",
  height = 70,
  width = 70,
  heightLayout,
  timeout
}) => {
  const customClass = cx({
    theme_standard_loading: theme_standard_loading,
  });

  return (
    <LoadingStyled
      heightLayout={heightLayout}
    >
      <div className={customClass}>
        <Loader.Oval
          color={color}
          height={height}
          secondaryColor={color2}
          width={width}
          timeout={timeout} //3 secs
        />
      </div>
    </LoadingStyled>
  );
};

Loading.propTypes = {};

export default Loading;
