import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputSwitchStyled } from './styled';
import Switch from 'react-switch';
import theme from 'styles/theme.json';

const InputSwitch = ({
  theme_standard,
  theme_blue,
  disabled,
  onChange,
  value,
  color,
  label,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_blue: theme_blue,
  });

  return (
    <InputSwitchStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <Switch
          disabled={disabled}
          onChange={onChange}
          checked={value}
          onColor={color ? color : theme.COLORS.BLUE_3}
        />
      </div>
    </InputSwitchStyled>
  );
};

InputSwitch.propTypes = {};

export default InputSwitch;
