import styled from "styled-components";

export const LoginFormStyled = styled.div`
  width: 100%;
  .title_form {
    margin-bottom: 62px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S22};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
  }
  .input_wrap {
    margin-bottom: 30px;
    &.mb_37 {
      margin-bottom: 37px;
    }
  }
`;
