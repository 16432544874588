import styled from "styled-components";

export const BgIconPrefixStyled = styled.div`
  .btn {
    border-radius: 10px;
    border: 1px solid
      ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.BLUE_6 : theme.COLORS.GRAY_2};
    padding: 15px 0.75rem;
    width: 100%;
    min-height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    background: ${({ theme, disabled }) =>
      !disabled ? theme.COLORS.BLUE_6 : theme.COLORS.GRAY_2};
    color: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.BLACK_2 : theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    &:hover {
      background: #165ac8;
      transition: 0.7s;
    }
  }

  .theme_add {
    .btn {
      background: unset;
      color: ${({ theme }) => theme.COLORS.BLUE_6};
    }
  }

  .theme_cancel {
    .btn {
      background: ${({ theme }) => theme.COLORS.BLUE_7};
      color: ${({ theme }) => theme.COLORS.GRAY_8};
    }
  }
  .theme_stadard_btn {
  }
`;
