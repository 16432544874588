import React, { useState, useEffect } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ColorStyled } from './styled';
import { SketchPicker } from 'react-color';
import { useComponentVisible } from 'utils/functions/detectClickOutside';

const Color = ({ theme_standard, errors, value, onChange }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const {
    ref,
    isComponentVisible,
    // setIsComponentVisible
  } = useComponentVisible();

  const [_active, _setActive] = useState(false);

  useEffect(() => {
    if (isComponentVisible === false) {
      // _setInsertHeartRateModalShow(false);
      _setActive(false);
    }
  }, [isComponentVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleActive = () => {
    _setActive(!_active);
  };

  const handleChangeComplete = (color) => {
    onChange && onChange(color.hex);
  };

  return (
    <ColorStyled color={value}>
      <div className={customClass} ref={ref}>
        <div className="input_color_box" onClick={_handleActive}>
          <div className="i_color" />
        </div>
        {_active && (
          <div className="box_color_modal">
            <SketchPicker color={value} onChange={handleChangeComplete} />
          </div>
        )}
        {errors && <div className="errors">{errors}</div>}
      </div>
    </ColorStyled>
  );
};

Color.propTypes = {};

export default Color;
