import React from "react";
import { SuccessContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { MdOutlineHome } from "react-icons/md";
import { Buttons } from "components";

class SuccessContainer extends React.Component {
  state = {
    isLoading: true,
  };

  handleHome = () => {
    this.props.router.navigate(ROUTE_PATH.EMPLOYEE_HOME);
  };

  render() {
    return (
      <SuccessContainerStyled>
        <div className="Home_container">
          <div className="card_container">
            <div className="card">
              <div className="main_title">
                <div>ท่านได้ทำการ</div>
                <div>Check in ลงเวลางาน</div>
                <div>สำเร็จแล้ว</div>
              </div>
            </div>
          </div>
          <div className="button_home">
            <Buttons.BgStandard
              theme_stadard_btn
              icon={<MdOutlineHome className="icon" />}
              label={"Home"}
              onClick={this.handleHome}
            />
          </div>
        </div>
      </SuccessContainerStyled>
    );
  }
}

export default withRouter(SuccessContainer);
