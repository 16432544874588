import React from "react";
import { connect } from "react-redux";
import { HomeContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { setLogoutThunk } from "store/reduxThunks/logoutThunk";
import { BtnScanQRWidget } from "widgets";
import { Buttons } from "components";

class HomeContainer extends React.Component {
  state = {
    isLoading: true,
  };

  handleCheckIn = async () => {
    this.props.router.navigate(ROUTE_PATH.EMPLOYEE_QR_START);
  };

  handleCheckOut = async () => {
    this.props.router.navigate(ROUTE_PATH.EMPLOYEE_QR_START);
  };

  handleNextPage = (routePath) => {
    this.props.router.navigate(routePath);
  };

  handleClickLogout = async () => {
    let res = await this.props.setLogoutThunk();
    if (res) {
      this.props.router.navigate(ROUTE_PATH.ROOT);
    }
  };

  render() {
    const { authenRedux } = this.props;
    return (
      <HomeContainerStyled>
        <div className="head_title">{`คุณ ${authenRedux.first_name} ${authenRedux.last_name}`}</div>
        <div className="menu_item_wrap">
          <BtnScanQRWidget
            label="Check in ลงเวลางาน"
            workLogType="1"
            onScanSuccess={this.handleCheckIn}
          />
        </div>
        <div className="menu_item_wrap mb_64">
          <BtnScanQRWidget
            label="Check out ลงเวลางาน"
            workLogType="2"
            onScanSuccess={this.handleCheckOut}
          />
        </div>
        <div className="menu_item_wrap">
          <Buttons.BgStandard
            theme_stadard_btn
            label={"ภาพรวมการขาย"}
            onClick={() =>
              this.handleNextPage(ROUTE_PATH.EMPLOYEE_SALE_OVERVIEW)
            }
          />
        </div>
        <div className="menu_item_wrap mb_74">
          <Buttons.BgStandard
            theme_stadard_btn
            label={"ดูเวลาเข้าออกงาน"}
            onClick={() => this.handleNextPage(ROUTE_PATH.EMPLOYEE_TIMEWORK)}
          />
        </div>
        <div className="menu_item_wrap">
          <Buttons.BgStandard
            theme_stadard_btn
            label={"แก้ไขรหัสผ่าน"}
            onClick={() => this.handleNextPage(ROUTE_PATH.CHANGE_PASSWORD)}
          />
        </div>
        <div className="menu_item_wrap">
          <Buttons.BgStandard
            theme_gray
            label={"ออกจากระบบ"}
            onClick={this.handleClickLogout}
          />
        </div>
        {/* <div className="button_home">
          <Buttons.BgStandard
            theme_menu_btn
            icon={<Icons.MenuHome />}
            label={"Home"}
          />
        </div> */}
      </HomeContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setLogoutThunk: (data) => dispatch(setLogoutThunk(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomeContainer));
