import styled from "styled-components";

export const ChangePasswordFormStyled = styled.div`
  .input_wrap {
    margin-bottom: 12px;
    &.mb_37 {
        margin-bottom: 37px;
    }
  }
`;
