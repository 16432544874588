import styled from "styled-components";

export const HomeContainerStyled = styled.div`
  padding: 24px 20px 78px 20px;
  .head_title {
    margin-bottom: 22px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .menu_item_wrap {
    margin-bottom: 20px;
    &.mb_64 {
      margin-bottom: 54px;
    }
    &.mb_74 {
      margin-bottom: 64px;
    }
  }
  .button_home {
    position: absolute;
    bottom: 16px;
    left: 0px;
    right: 0px;
    margin: auto;
    max-width: 152px;
  }
`;
