import ChangePasswordForm from "./ChangePasswordForm";
import CusActivityForm from "./CusActivityForm";
import LoginForm from "./LoginForm";
import VerifyOTPForm from "./VerifyOTPForm";

const EXPORT = {
  ChangePasswordForm,
  CusActivityForm,
  LoginForm,
  VerifyOTPForm,
};

export default EXPORT;
