import styled from 'styled-components';

export const InputSwitchStyled = styled.div`
  .label {
    margin-bottom: 8px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
    color: ${({ theme }) => theme.COLORS.GRAY_16};
  }

  .theme_standard {
  }
`;
