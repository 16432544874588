import styled from 'styled-components';

export const InputMultiSelectStyled = styled.div`
  .label {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }

  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
    .input_type_wrap {
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      .itw_left_box {
        width: 20%;
        display: flex;
        align-items: center;
      }
      .itw_right_box {
        width: 40%;
      }
    }
  }
`;
