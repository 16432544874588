import React from "react";
import { ConfirmContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { MdCheckCircle, MdOutlineHome } from "react-icons/md";
import { Buttons } from "components";

class ConfirmContainer extends React.Component {
  state = {
    isLoading: true,
  };

  handleSubmit = () => {
    this.props.router.navigate(ROUTE_PATH.EMPLOYEE_SUCCESS);
  };

  handleHome = () => {
    this.props.router.navigate(ROUTE_PATH.EMPLOYEE_HOME);
  };

  render() {
    return (
      <ConfirmContainerStyled>
        <div className="Home_container">
          <div className="head_title">Check in ลงเวลางาน</div>
          <div className="btn_action_filter">
            <div className="button_group">
              <Buttons.BgStandard
                theme_check_btn
                icon={<MdCheckCircle className="icon_check" />}
                label={
                  <div className="text_button">
                    <div>กดเพื่อยืนยัน</div>
                    <div>Check in ลงเวลางาน</div>
                  </div>
                }
                onClick={this.handleSubmit}
              />
            </div>
          </div>
          <div className="button_home">
            <Buttons.BgStandard
              theme_stadard_btn
              icon={<MdOutlineHome className="icon" />}
              label={"Home"}
              onClick={this.handleHome}
            />
          </div>
        </div>
      </ConfirmContainerStyled>
    );
  }
}

export default withRouter(ConfirmContainer);
