import React from "react";
import { ChangePasswordContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { userService } from "apiServices";
import { ROUTE_PATH } from "utils/constants/routePath";
import { toast } from "react-toastify";
import { Buttons, Displays, Forms, Icons } from "components";

class ChangePasswordContainer extends React.Component {
  state = {};

  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    let res = await userService.POST_CHANGE_PASSWORD(params);
    if (res && res.status === 200) {
      this.setState({
        isBgLoading: false,
      });
      toast.success("เปลี่ยนรหัสผ่านสำเร็จ");
      this.props.router.navigate(ROUTE_PATH.EMPLOYEE_HOME);
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error("เปลี่ยนรหัสผ่านไม่สำเร็จ");
    }
  };

  handleHome = () => {
    this.props.router.navigate(ROUTE_PATH.EMPLOYEE_HOME);
  };

  render() {
    const { isBgLoading } = this.state;
    return (
      <ChangePasswordContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Forms.ChangePasswordForm onSubmit={this.handleSubmit} />
        <div className="button_home">
          <Buttons.BgStandard
            theme_menu_btn
            icon={<Icons.MenuHome />}
            label={"Home"}
            onClick={this.handleHome}
          />
        </div>
      </ChangePasswordContainerStyled>
    );
  }
}

export default withRouter(ChangePasswordContainer);
