import React from "react";
import { SaleOverviewContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import queryString from "query-string";
import { userService } from "apiServices";
import moment from "moment";
import { Displays, Tables, Inputs, Buttons, Icons } from "components";

class SaleOverviewContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(
      `?pagination=false&start_date=${moment()
        .startOf("month")
        .format("YYYY-MM-DD")}&end_date=${moment()
        .endOf("month")
        .format("YYYY-MM-DD")}`
    );
  }

  fetchData = async (queryString) => {
    let res = await userService.GET_MY_TRANSACTION_LIST(queryString);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleFilterKeyword = (e) => {
    this.setState({
      isBgLoading: true,
    });
    this.fetchData(
      !e
        ? `?pagination=false&start_date=${moment()
            .startOf("month")
            .format("YYYY-MM-DD")}&end_date=${moment()
            .endOf("month")
            .format("YYYY-MM-DD")}`
        : `${e}&pagination=false`
    );
  };

  handleHome = () => {
    this.props.router.navigate(ROUTE_PATH.EMPLOYEE_HOME);
  };

  render() {
    const { router } = this.props;
    const { isBgLoading, isLoading, pagination, data } = this.state;
    return (
      <SaleOverviewContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <div className="Sale_container">
            <div className="head_title">ภาพรวมการขาย</div>
            <div className="Table_Container">
              <Inputs.InputFilter
                theme_standard_input_filter
                dataFilter={dataFilter(router.location?.search)}
                btnLabel="ค้นหา"
                onQueryFilter={this.handleFilterKeyword}
              />
              <div className="body_table">
                <Tables.Standard
                  columns={columns()}
                  data={data?.docs}
                  showPagination
                  pagination={pagination}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            </div>
            <div className="button_home">
              <Buttons.BgStandard
                theme_menu_btn
                icon={<Icons.MenuHome />}
                label={"Home"}
                onClick={this.handleHome}
              />
            </div>
          </div>
        )}
      </SaleOverviewContainerStyled>
    );
  }
}

const dataFilter = (query) => {
  let initialValues = queryString.parse(query);
  return [
    {
      accessor: "month",
      length: "130px",
      filter: {
        fieldQuery: "month",
        defaultValue: initialValues["month"],
        type: "dateMonth",
        placeholder: "เดือน",
        options: [],
      },
    },
  ];
};

const columns = () => [
  {
    Header: <div style={{ textAlign: "left" }}>วันเวลาลงทะเบียน</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div>{moment(props.value).format("DD/MM/YYYY HH:mm")}</div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>ชื่อ</div>,
    accessor: "cus_first_name",
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>นามสกุล</div>,
    accessor: "cus_last_name",
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>เบอร์โทร</div>,
    accessor: "cus_phone_number",
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>ผลิตภัณฑ์ที่ซื้อ</div>,
    accessor: "product_name",
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>ร้านที่ซื้อ</div>,
    accessor: "shop_name",
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>OTP</div>,
    accessor: "is_verify_otp",
    Cell: (props) => <div>{props.value ? "Yes" : "No"}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>เลขที่ใบเสร็จ</div>,
    accessor: "invoice_number",
    Cell: (props) => <div>{props.value}</div>,
  },
];

export default withRouter(SaleOverviewContainer);
