import { NavbarWidgetStyled } from "./styled";

const NavbarWidget = () => {
  return (
    <NavbarWidgetStyled>
      <img className="logo" alt="logo" src="/assets/images/logos/logo1.png" />
    </NavbarWidgetStyled>
  );
};

export default NavbarWidget;
