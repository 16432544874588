import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputAutoSuggestionStyled } from './styled';
import Autosuggest from 'react-autosuggest';

// Teach Autosuggest how to calculate suggestions for any given input value.
// const getSuggestions = (value) => {
//   const inputValue = value.trim().toLowerCase();
//   const inputLength = inputValue.length;

//   return inputLength === 0
//     ? []
//     : languages.filter(
//         (lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue
//       );
// };

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.user_id;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion.user_id}</div>;

const InputAutoSuggestion = ({
  theme_standard,
  value,
  onChange,
  apiService,
  query,
  placeholder,
  label,
  errors,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [suggestions, setSuggestions] = useState([]);

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {
    let res = await apiService(`${query}=${value}`);
    if (res && res.status === 200) {
      setSuggestions(res.data.docs);
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: placeholder,
    value,
    onChange: (event, { newValue }) => onChange(newValue),
  };

  return (
    <InputAutoSuggestionStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </InputAutoSuggestionStyled>
  );
};

InputAutoSuggestion.propTypes = {};

export default InputAutoSuggestion;
