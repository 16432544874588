import styled from 'styled-components';

export const UploadSetImageStyled = styled.div`
  .input_upload_container {
    display: flex;
    row-gap: 10px;
    column-gap: 10px;
    flex-wrap: wrap;
    .label {
      margin-bottom: 0.5rem;
      color: ${({ theme }) => theme.COLORS.INPUT_TEXT_FIELD_LABEL};
      font-size: ${({ theme }) => theme.FONT.SIZE.S15};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
    .count_show {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-weight: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.INPUT_TEXT_FIELD_LABEL};
      width: 100%;
      display: flex;
      justify-content: space-between;
      .total_pic {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      }
    }
    .show_img {
      position: relative;
      border-radius: 12px;
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      -webkit-box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      width: 127px;
      height: 112px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .close {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
        z-index: 2;
      }
    }
    .input_upload {
      .box_upload {
        border-radius: 0.25rem;
        background: #dddddd;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
        -webkit-box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
        .show_svg {
          display: flex;
        }
        .h1 {
          margin-left: 9px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-weight: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.INPUT_IMG_UPLOAD_TXT};
          text-align: center;
        }
      }
    }
  }
  .errors {
    margin-top: 6px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.INPUT_ERR_1};
  }
`;
