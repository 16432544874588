import styled from "styled-components";

export const BgStandardStyled = styled.div`
  .btn {
    border-radius: 23px;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_6};
    border: ${({ disabled }) => disabled && "none"};
    padding: 0.625rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: ${({ theme, disabled }) =>
      !disabled ? theme.COLORS.BLUE_6 : theme.COLORS.GRAY_4};
    color: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.BLACK_2 : theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    &:hover {
      background: ${({ disabled }) => !disabled && "#165ac8"};
      transition: 0.7s;
    }
  }

  .theme_stadard_btn {
    .btn {
      border-radius: 20px;
      border: 1px solid ${({ theme }) => theme.COLORS.BLUE_6};
      border: ${({ disabled }) => disabled && "none"};
      height: 60px;
      padding: 0.625rem 1rem;
      column-gap: 1rem;
      width: 100%;
      background: ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.BLUE_6 : theme.COLORS.GRAY_4};
      color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.BLACK_2 : theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      &:hover {
        background: ${({ disabled }) => !disabled && "#165ac8"};
        transition: 0.7s;
      }
    }
  }

  .theme_check_btn {
    .btn {
      border-radius: 20px;
      border: 1px solid ${({ theme }) => theme.COLORS.BLUE_6};
      border: ${({ disabled }) => disabled && "none"};
      height: 100px;
      padding: 0.625rem 1rem;
      column-gap: 1rem;
      width: 100%;
      background: ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.BLUE_6 : theme.COLORS.GRAY_4};
      color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.BLACK_2 : theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      &:hover {
        background: ${({ disabled }) => !disabled && "#165ac8"};
        transition: 0.7s;
      }
    }
  }

  .theme_export_btn {
    .btn {
      border: 1px solid ${({ theme }) => theme.COLORS.ORANGE_1};
      padding: 0.625rem 1rem;
      background: ${({ theme, disabled }) =>
        !disabled ? `${theme.COLORS.ORANGE_1}` : theme.COLORS.GRAY_4};
      &:hover {
        background: ${({ theme }) => theme.COLORS.ORANGE_1}CC;
        transition: 0.7s;
      }
    }
  }

  .theme_gray {
    .btn {
      background: ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.GRAY_7 : theme.COLORS.GRAY_4};
      color: ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.BLUE_2 : theme.COLORS.WHITE_1};
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_7};
    }
  }

  .theme_red {
    .btn {
      background: ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.RED_1 : theme.COLORS.GRAY_4};
      color: ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.WHITE_1 : "#1010104D"};
      border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
      border: ${({ disabled }) => disabled && "none"};
    }
  }

  .theme_pink {
    .btn {
      background: ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.PINK_1 : theme.COLORS.GRAY_4};
      color: ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.WHITE_1 : "#1010104D"};
      border: 1px solid ${({ theme }) => theme.COLORS.PINK_1};
      border: ${({ disabled }) => disabled && "none"};
    }
  }

  .theme_menu_btn {
    .btn {
      border-radius: 10px;
      column-gap: 12px;
      padding: 0px 14px;
      height: 54px;
      color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.BLACK_2 : theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    }
  }
`;
