import styled from 'styled-components';

export const AvatarStyled = styled.div`
  .avatar {
    border-radius: 34px;
    width: 34px;
    height: 34px;
    background: ${({ theme }) => theme.COLORS.GRAY_3};
  }

  .theme_standard {
  }
`;
