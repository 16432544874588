import apiService from "../apiService";

const apiPath = "/api/v1";

export const userService = {
  POST_LOGIN: (params) => {
    return apiService.post(`${apiPath}/pc-login/employee`, params);
  },
  GET_MY_PROFILE: () => {
    return apiService.get(`${apiPath}/pc/me`);
  },
  POST_CHECK_TOKEN: () => {
    return apiService.get(`${apiPath}/pc-login/employee/check_token`);
  },
  POST_CHANGE_PASSWORD: (params) => {
    return apiService.post(`${apiPath}/pc/me/change-password`, params);
  },

  // TRANSACTION
  GET_MY_TRANSACTION_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/transaction/me${stringQuery ? stringQuery : ""}`
    );
  },
  POST_CHECK_LOCATION: (params) => {
    return apiService.post(`${apiPath}/transaction/check-location`, params);
  },

  // WORK LOG
  GET_MY_WORK_LOG_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/work-log/me${stringQuery ? stringQuery : ""}`
    );
  },
  POST_CHECKIN_WORK: (params) => {
    return apiService.post(`${apiPath}/work-log/check-in`, params);
  },
  POST_CHECKOUT_WORK: (params) => {
    return apiService.post(`${apiPath}/work-log/check-out`, params);
  },

  // PC
  GET_PC_DETAIL: (id) => {
    return apiService.get(`${apiPath}/pc/${id}`);
  },

  // PRODUCT
  GET_PRODUCT_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/product${stringQuery ? stringQuery : ""}`
    );
  },

  // TRANSACTION
  POST_REGIS_TRANSACTION_VERIFY: (params) => {
    return apiService.post(
      `${apiPath}/transaction/regis/check-condition`,
      params
    );
  },
  POST_TRANSACTION_UPLOAD: (params) => {
    return apiService.post_formdata(`${apiPath}/transaction/regis`, params);
  },

  // OTP
  POST_OTP_REQUEST: (params) => {
    return apiService.post(`${apiPath}/sms/request-otp`, params);
  },
  POST_OTP_VERIFY: (params) => {
    return apiService.post(`${apiPath}/sms/verify-otp`, params);
  },
};
