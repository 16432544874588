import React from 'react';
import { PaginationCustomStyled } from './styled';
import cx from 'classnames';
import ReactPaginate from 'react-paginate';
import { Icons } from 'components'

const PaginationCustom = ({
  theme_standard_pagination,
  currentPageData,
  totalPages,
  _handlePageClick,
}) => {
  const customClass = cx({
    theme_standard_pagination: theme_standard_pagination,
  });

  return (
    <PaginationCustomStyled>
      <div className={customClass}>
        <ReactPaginate
          forcePage={currentPageData}
          previousLabel={<div style={{ display: 'flex' }}><Icons.ArrowLeftRight /></div>}
          nextLabel={<div style={{ display: 'flex' }}><Icons.ArrowLeftRight right /></div>}
          breakLabel={'...'}
          breakClassName={'break-me'}
          // pageCount={this.state.pageCount}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={_handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      </div>
    </PaginationCustomStyled>
  );
};

export default PaginationCustom;