import React, { useEffect } from "react";
import { CusActivityFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const CusActivityForm = ({ intialValues, productOptions, onSubmit }) => {
  const schema = yup.object().shape({
    cus_first_name: yup.string().required("โปรดกรอกข้อมูล"),
    cus_last_name: yup.string().required("โปรดกรอกข้อมูล"),
    cus_phone_number: yup.string().required("โปรดกรอกเบอร์โทรศัพท์"),
    product_ref_id: yup.string().required("โปรดกรอกผลิตภัณฑ์ที่ซื้อ"),
    // invoice_number: yup.string().required("โปรดกรอกเลขที่ใบเสร็จรับเงิน"),
    // image_file: yup.array().required("โปรดถ่ายภาพใบเสร็จรับเงิน"),
    is_accept_pdpa: yup.bool().nullable().oneOf([true], "กรุณายอมรับเงื่อนไข"),
    is_accept_term_condition: yup
      .bool()
      .nullable()
      .oneOf([true], "กรุณายอมรับเงื่อนไข"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (intialValues) {
      reset({ ...intialValues });
    }
  }, [intialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CusActivityFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body_form_campaign">
          <div className="input_wrap">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    placeholder="ชื่อ"
                    errors={errors.cus_first_name?.message}
                  />
                )}
                name="cus_first_name"
                defaultValue=""
                place
              />
            </div>
          </div>
          <div className="input_wrap">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    placeholder="นามสกุล"
                    errors={errors.cus_last_name?.message}
                  />
                )}
                name="cus_last_name"
                defaultValue=""
                place
              />
            </div>
          </div>
          <div className="input_wrap">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    placeholder="เบอร์โทรศัพท์"
                    errors={errors.cus_phone_number?.message}
                  />
                )}
                name="cus_phone_number"
                defaultValue=""
              />
            </div>
          </div>
          <div className="input_wrap">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={productOptions}
                    placeholder="ผลิตภัณฑ์ที่ซื้อ"
                    errors={errors.product_ref_id?.message}
                  />
                )}
                name="product_ref_id"
                defaultValue=""
              />
            </div>
          </div>
          {/* <div className="input_wrap">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    placeholder="เลขที่ใบเสร็จรับเงิน"
                    errors={errors.invoice_number?.message}
                  />
                )}
                name="invoice_number"
                defaultValue=""
              />
            </div>
          </div> */}
          {/* <div className="input_wrap">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Upload
                    {...field}
                    acceptType="cover"
                    errors={errors.image_file?.message}
                  />
                )}
                name="image_file"
                // defaultValue=""
              />
            </div>
          </div> */}
          <div className="input_wrap">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputCheckBox
                    {...field}
                    label={"ยอมรับเงื่อนไข-การให้ข้อมูลส่วนตัว"}
                    errors={errors.is_accept_pdpa?.message}
                  />
                )}
                name="is_accept_pdpa"
                defaultValue={false}
              />
            </div>
          </div>

          <div className="input_wrap">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputCheckBox
                    {...field}
                    label={"ยอมรับเงื่อนไขและกติกาการเข้าร่วมกิจกรรม"}
                    errors={errors.is_accept_term_condition?.message}
                  />
                )}
                name="is_accept_term_condition"
                defaultValue={false}
              />
            </div>
          </div>
          <div className="btn_zone">
            <Buttons.BgStandard
              theme_stadard_btn
              type="submit"
              label="ลงทะเบียน"
              // disabled={initialValues && !initialValues.is_edit}
            />
          </div>
        </div>
      </form>
    </CusActivityFormStyled>
  );
};

CusActivityForm.propTypes = {};

export default CusActivityForm;
