import AddRound from "./AddRound";
import ArrowDropdown from "./ArrowDropdown";
import ArrowLeftRight from "./ArrowLeftRight";
import Camera from "./Camera";
import Cancel from "./Cancel";
import CloseX from "./CloseX";
import Confirm from "./Confirm";
import Loading from "./Loading";
import MenuHome from "./MenuHome";
import Resend from "./Resend";
import Success from "./Success";

const EXPORT = {
  AddRound,
  ArrowDropdown,
  ArrowLeftRight,
  Camera,
  Cancel,
  CloseX,
  Confirm,
  Loading,
  MenuHome,
  Resend,
  Success,
};

export default EXPORT;
