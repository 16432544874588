import Avatar from "./Avatar";
import Loading from "./Loading";
import BGLoading from "./BGLoading";
import PaginationCustom from "./PaginationCustom";
import LabelValue from "./LabelValue";

const EXPORT = {
  Avatar,
  BGLoading,
  Loading,
  PaginationCustom,
  LabelValue,
};

export default EXPORT;
