import React from "react";
import { LoginFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const LoginForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    employee_code: yup.string().required("กรุณากรอก"),
    password: yup.string().required("กรุณากรอก"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <LoginFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="title_form">ยินดีต้อนรับเข้าสู่ระบบ Abbott</div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder="*รหัสพนักงาน"
                errors={errors.employee_code?.message}
              />
            )}
            name="employee_code"
            defaultValue=""
          />
        </div>
        <div className="input_wrap mb_37">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder="*รหัสผ่าน"
                type="password"
                errors={errors.password?.message}
              />
            )}
            name="password"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard type="submit" label="ล็อคอิน" />
      </form>
    </LoginFormStyled>
  );
};

LoginForm.propTypes = {};

export default LoginForm;
