import React, { useEffect, useState } from "react";

// import PropTypes from 'prop-types'
import { VerifyOTPStyled } from "./styled";
import { userService } from "apiServices";
import { Forms } from "components";
import { toast } from "react-toastify";

const VerifyOTP = ({ phoneNumber, onCancel, onVerifySuccess }) => {
  useEffect(() => {
    _requestOTP();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [_dataOTP, _setDataOTP] = useState();

  const _requestOTP = async () => {
    let params = {
      phone_number: phoneNumber,
    };
    let res = await userService.POST_OTP_REQUEST(params);
    if (res && res.status === 200) {
      _setDataOTP(res.data);
    }
  };

  const _handleClickResendOTP = () => {
    _requestOTP();
  };

  const _handleSubmit = async (values) => {
    let params = {
      token: _dataOTP.request_id,
      otp_code: values.code,
    };
    let res = await userService.POST_OTP_VERIFY(params);
    if (res && res.status === 200) {
      onVerifySuccess && onVerifySuccess();
    } else {
      toast.error("OTP code invalid");
    }
  };

  return (
    <VerifyOTPStyled>
      <div className="v_otp_title">
        <Forms.VerifyOTPForm
          phoneNumber={phoneNumber}
          onCancel={onCancel}
          onClickResendOTP={_handleClickResendOTP}
          onSubmit={_handleSubmit}
        />
      </div>
    </VerifyOTPStyled>
  );
};

VerifyOTP.propTypes = {};

export default VerifyOTP;
