import styled from "styled-components";

export const QrCodeContainerStyled = styled.div`
  min-height: 100vh;
  .QrCode_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;
    .head_title {
      font-size: 30px;
      display: flex;
      justify-content: center;
      padding-top: 50px;
    }
    .main_qr {
      display: flex;
      justify-content: center;
      padding: 30px;
    }
    .card {
      margin: 10px;
      height: 70px; /* ปรับขนาดตามที่คุณต้องการ */
      background-color: white; /* สีพื้นหลังขาว */
      border: 2px solid #707070; /* เส้นขอบสีดำขนาด 2px */
      padding-top: 35px;
      display: flex;
      justify-content: center;

      .main_title {
        font-size: 28px;
        display: flex;
        justify-content: center;
      }
    }

    .footer_title {
      display: flex;
      justify-content: center;
      font-size: 22px;
      padding-top: 50px;
      margin-bottom: 10px;
    }
  }
`;
