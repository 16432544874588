import styled from "styled-components";

export const SuccessContainerStyled = styled.div`
  min-height: 100vh;
  .Home_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;
    .card_container {
      padding-top: 50%;
      .card {
        margin: 10px;

        background-color: white; /* สีพื้นหลังขาว */
        border-radius: 20px;
        border: 1px solid #707070;
        padding: 35px;
        display: flex;
        justify-content: center;

        .main_title {
          font-size: 18px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: center;
        }
      }
    }

    .button_home {
      position: absolute;
      bottom: 10px; /* Adjust this value to control the distance from the bottom */
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      display: flex;
      justify-content: center; /* Center horizontally */
    }
  }
`;
