import React, { useEffect } from 'react';
// import PropTypes from 'prop-types'
import { BodyEmptyStyled } from './styled';

const BodyEmpty = ({ isShowModal, onCloseModal, children, isForceAction }) => {
  useEffect(() => {
    if (isShowModal) {
      _handleClickEnbModal();
    } else {
      _handleClickDisModal();
    }
  }, [isShowModal]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleClickEnbModal = () => {};

  const _handleClickDisModal = () => {
    if (!isForceAction) {
      onCloseModal && onCloseModal();
    }
  };

  return !isShowModal ? (
    <></>
  ) : (
    <BodyEmptyStyled>
      <div className="backdrop" onClick={_handleClickDisModal} />
      {children}
    </BodyEmptyStyled>
  );
};

BodyEmpty.propTypes = {};

export default BodyEmpty;
