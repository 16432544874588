import styled from "styled-components";

export const ConfirmContainerStyled = styled.div`
  min-height: 100vh;
  .Home_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;
    .head_title {
      font-size: 30px;
      display: flex;
      justify-content: center;
      padding-top: 50px;
    }
    .btn_action_filter {
      margin: 50px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 320px;
      row-gap: 30px;
      .button_group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 10px;
        .icon_check {
          width: 40px;
          height: auto;
        }
        .text_button {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: start;
        }
      }
    }
    .button_home {
      position: absolute;
      bottom: 10px; /* Adjust this value to control the distance from the bottom */
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      display: flex;
      justify-content: center; /* Center horizontally */
    }
  }
`;
