import { Routes, Route } from "react-router-dom";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Mainlayouts } from "components";
import LoginContainer from "containers/LoginContainer";
import HomeContainer from "containers/HomeContainer";
import ChangePasswordContainer from "containers/ChangePasswordContainer";
import QrCodeContainer from "containers/CheckInOutSystem/QrCodeContainer";
import TimeWorkContainer from "containers/TimeWorkContainer";
import ConfirmContainer from "containers/CheckInOutSystem/ConfirmContainer";
import SuccessContainer from "containers/CheckInOutSystem/SuccessContainer";
import DeniedContainer from "containers/CheckInOutSystem/DeniedContainer";
import SaleOverviewContainer from "containers/SaleOverviewContainer";
import CusActivityContainer from "containers/CusActivityContainer";

const Navigations = () => {
  return (
    <Routes>
      <Route element={<Mainlayouts.PageLayout />}>
        <Route path={ROUTE_PATH.LOGIN} element={<LoginContainer />} />
        <Route path={ROUTE_PATH.EMPLOYEE_HOME} element={<HomeContainer />} />
        <Route
          path={ROUTE_PATH.CHANGE_PASSWORD}
          element={<ChangePasswordContainer />}
        />
        <Route
          path={ROUTE_PATH.EMPLOYEE_QR_START}
          element={<QrCodeContainer />}
        />
        <Route
          path={ROUTE_PATH.EMPLOYEE_TIMEWORK}
          element={<TimeWorkContainer />}
        />
        <Route
          path={ROUTE_PATH.EMPLOYEE_CONFIRM}
          element={<ConfirmContainer />}
        />
        <Route
          path={ROUTE_PATH.EMPLOYEE_SUCCESS}
          element={<SuccessContainer />}
        />
        <Route
          path={ROUTE_PATH.EMPLOYEE_DENIED}
          element={<DeniedContainer />}
        />
        <Route
          path={ROUTE_PATH.EMPLOYEE_SALE_OVERVIEW}
          element={<SaleOverviewContainer />}
        />
        <Route
          path={ROUTE_PATH.EMPLOYEE_SALE_OVERVIEW}
          element={<SaleOverviewContainer />}
        />
        <Route path="*" element={<LoginContainer />} />
      </Route>
      <Route element={<Mainlayouts.CustomerLayout />}>
        <Route
          path={ROUTE_PATH.CUS_ACTIVITY}
          element={<CusActivityContainer />}
        />
      </Route>
    </Routes>
  );
};

export default Navigations;
