import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { BgStandardStyled } from "./styled";

const BgStandard = ({
  theme_stadard_btn,
  theme_gray,
  theme_red,
  theme_export_btn,
  theme_pink,
  theme_check_btn,
  theme_menu_btn,
  type = "button",
  label,
  onClick,
  icon,
  disabled,
  color,
}) => {
  const customClass = cx({
    theme_stadard_btn: theme_stadard_btn,
    theme_gray: theme_gray,
    theme_export_btn: theme_export_btn,
    theme_pink: theme_pink,
    theme_check_btn: theme_check_btn,
    theme_red: theme_red,
    theme_menu_btn: theme_menu_btn,
  });
  return (
    <BgStandardStyled disabled={disabled} color={color}>
      <div className={customClass}>
        <button
          type={type}
          disabled={disabled}
          className="btn"
          onClick={onClick}
        >
          {icon}
          {label}
        </button>
      </div>
    </BgStandardStyled>
  );
};

BgStandard.propTypes = {};

export default BgStandard;
