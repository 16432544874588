import styled from "styled-components";

export const SaleOverviewContainerStyled = styled.div`
  .Sale_container {
    padding: 27px 6px 14px 6px;
    .head_title {
      font-size: 30px;
      display: flex;
      justify-content: center;
    }
    .Table_Container {
      margin-top: 20px;
      margin-bottom: 18px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      .body_table {
        margin-top: 20px;
        border-radius: 1rem;
        padding: 1.25rem;
        background: #eaedfc;
        overflow-x: auto;
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th,
        td {
          width: 100%;
          padding: 10px;

          white-space: nowrap; /* คำสั่งที่ทำให้ข้อความไม่ขึ้นบรรทัดใหม่ */
        }
      }
    }
    .button_home {
      display: flex;
      justify-content: center; /* Center horizontally */
    }
  }
`;
