export const ROUTE_PATH = {
  ROOT: "/",
  LOGIN: "/login",
  EMPLOYEE_HOME: "/employee/home",
  CHANGE_PASSWORD: "/employee/change-password",
  EMPLOYEE_QR_START: "/employee/start",
  EMPLOYEE_CONFIRM: "/employee/confirm",
  EMPLOYEE_QR_END: "/employee/end",
  EMPLOYEE_TIMEWORK: "/employee/timework",
  EMPLOYEE_SUCCESS: "/employee/success",
  EMPLOYEE_DENIED: "/employee/denied",
  EMPLOYEE_SALE_OVERVIEW: "/employee/sale/overview",
  SYSTEM: "/system",
  CUS_ACTIVITY: "/customer-activity",
};
