import React, { useState, useEffect } from "react";
import { UploadStyled } from "./styled";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { useDropzone } from "react-dropzone";
import { Icons } from "components";

const Upload = ({
  theme_standard,
  onChange,
  acceptType,
  value,
  maxMBFileSizeWarning,
  errors,
  // inputImgUpload,
  disabled,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_files, _setFiles] = useState([]);

  const renderAcceptType = (type) => {
    switch (type) {
      case "cover":
        return {
          "image/jpeg": [".jpeg", ".png"],
        };
      case "png":
        return {
          "image/png": [".png"],
        };
      case "audio":
        return ".mp3,audio/mpeg3";
      case "video":
        return { "video/": [] };
      case "csv":
        return { "text/csv": [".csv"] };
      default:
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    noClick: disabled,
    accept: renderAcceptType(acceptType),
    onDrop: (acceptedFiles) => {
      if (maxMBFileSizeWarning) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(2)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          alert("ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล");
        }
      }
      let fileTemp = [
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isNewUpload: true,
          })
        ),
      ];
      _setFiles(fileTemp);
      onChange && onChange(fileTemp);
    },
  });

  useEffect(() => {
    if (value) {
      _setFiles(value);
    }
  }, [value]);

  // const onClickRemove = (index, data) => {
  //   if (data.isNewUpload) {
  //     let filesTemp = _files;
  //     filesTemp.splice(index, 1);
  //     _setFiles([...filesTemp]);
  //   } else {
  //     let filesTemp = _files;
  //     filesTemp[index].isDelete = true;
  //     _setFiles([...filesTemp]);
  //   }
  // };

  return (
    <UploadStyled>
      <div className={customClass}>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div className="input_upload_container">
            อัปโหลดรูปใบเสร็จ
            {_files && _files.length > 0 ? (
              <Icons.Success width={30} height={30} />
            ) : (
              <Icons.Camera width={34} height={27} color="#707070" />
            )}
          </div>
        </div>
        {errors && <div className="errors">{errors}</div>}
      </div>
    </UploadStyled>
  );
};

Upload.propTypes = {};

export default Upload;
