import styled from 'styled-components';

export const UploadStyled = styled.div`
  .dropzone {
    cursor: pointer;
    .input_upload_container {
      border-radius: 1rem;
      border: 0.0625rem solid ${({ theme }) => theme.COLORS.GRAY_1};
      padding: 0.3125rem 1.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${({ theme }) => theme.COLORS.GRAY_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    }
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
