import styled from 'styled-components';

export const ColorStyled = styled.div`
  .input_color_box {
    border-radius: 0.5rem;
    border: 0.0625rem solid ${({ theme }) => theme.COLORS.GRAY_1};
    width: fit-content;
    padding: 0.25rem;
    cursor: pointer;
    .i_color {
      border-radius: 0.2rem;
      width: 70px;
      height: 24px;
      background: ${({ color }) => color || 'transparent'};
    }
  }
  .box_color_modal {
    position: absolute;
    bottom: 10px;
  }

  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
