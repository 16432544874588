import React from "react";
import { connect } from "react-redux";
import { LoginContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { setReduxAuthen } from "store/reducers/authenReducer";
import { userService } from "apiServices";
import { toast } from "react-toastify";
import { Forms, Displays } from "components";

class LoginContainer extends React.Component {
  state = {};

  componentDidMount() {}

  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    let res = await userService.POST_LOGIN(params);
    if (res && res.status === 200) {
      localStorage.setItem("token", res.token);
      this.fetchProfile();
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error("ไม่พบสมาชิกนี้ในระบบ");
    }
  };

  fetchProfile = async () => {
    let res = await userService.GET_MY_PROFILE();
    if (res && res.status === 200) {
      this.props.setReduxAuthen(res.data);
      this.setState({
        isBgLoading: false,
      });
      toast.success("ล็อคอินสำเร็จ");
      this.props.router.navigate(ROUTE_PATH.EMPLOYEE_HOME);
    }
  };

  render() {
    const { isBgLoading } = this.state;
    return (
      <LoginContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="form_layout">
          <Forms.LoginForm onSubmit={this.handleSubmit} />
        </div>
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginContainer));
