import React, { useState, useEffect } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { UploadSetImageStyled } from './styled';
import { useDropzone } from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import { IMG_PATH } from '../../../utils/constants/imgPath';
import { Icons } from '../../../components';

const UploadSetImage = ({
  theme_upload_set_image,
  heightBox,
  acceptType,
  value,
  onChange,
  showCount = true,
  maxLength = 10,
  groupWidth,
  labeDetail,
  maxMBFileSizeWarning,
  width,
  name,
  errors,
  label
}) => {
  const customClass = cx({
    theme_upload_set_image: theme_upload_set_image,
  });

  const [_files, _setFiles] = useState([]);
  const [_initial, _setInitial] = useState(true);
  const [_countFile, _setCountFile] = useState(0);

  const renderAcceptType = (type) => {
    switch (type) {
      case 'cover':
        return {
          'image/png': ['.png'],
          'image/jpg': ['.jpg'],
          'image/jpeg': ['.jpeg'],
        };
      case 'audio':
        return '.mp3,audio/mpeg3';
      case 'video':
        return 'video/*';
      default:
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: renderAcceptType(acceptType),
    onDrop: async (acceptedFiles) => {
      if (maxMBFileSizeWarning) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(2)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          alert('ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล');
        }
      }
      const imageResize = await _resizeFile(acceptedFiles);
      let fileTemp = [
        ..._files,
        ...imageResize.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isNewUpload: true,
            fieldName: name,
          })
        ),
      ];
      _setInitial(false);
      _setFiles(fileTemp);
      onChange && onChange(fileTemp);
    },
  });

  const _resizeFile = (_files) => {
    return Promise.all(
      _files.map((e) => {
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            e,
            1024,
            1024,
            'jpeg',
            90,
            0,
            (uri) => {
              resolve(uri);
            },
            'file'
          );
        });
      })
    ).then((e) => {
      return e;
    });
  };

  useEffect(() => {
    let countFile = _files.filter((e) => !e.isDelete);
    _setCountFile(countFile.length);
    if (value && value.length > 0 && _initial) {
      _setFiles(value);
      if (value.length > 3) {
        _setInitial(false);
      }
    }
    if (value === false) {
      _setFiles([]);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickRemove = (index, data) => {
    if (data.isNewUpload) {
      let filesTemp = _files;
      filesTemp.splice(index, 1);
      _setFiles([...filesTemp]);
      _setCountFile(_countFile - 1);
      onChange && onChange(filesTemp);
    } else {
      let filesTemp = _files;
      filesTemp[index].isDelete = true;
      _setFiles([...filesTemp]);
      _setCountFile(_countFile - 1);
      onChange && onChange(filesTemp);
    }
  };

  return (
    <UploadSetImageStyled
      heightBox={heightBox}
      width={width}
      groupWidth={groupWidth}
    >
      <div className={customClass}>
        <div className="input_upload_container">
          {label && <div className="label">{label}</div>}
          {showCount && (
            <div className="count_show">
              <div>{labeDetail && labeDetail}</div>
              <div className="total_pic">{`${_countFile}/${maxLength}`}</div>
            </div>
          )}
          {_files &&
            _files.length > 0 &&
            _files.map((e, i) =>
              e.isNewUpload ? (
                <div
                  key={i + 1}
                  className="show_img"
                  style={{ backgroundImage: `url(${e.preview})` }}
                >
                  <div className="close" onClick={() => onClickRemove(i, e)}>
                    <Icons.CloseX width="20" height="20" color="#3260AD" />
                  </div>
                </div>
              ) : (
                !e.isDelete && (
                  <div
                    key={i + 1}
                    className="show_img"
                    style={{
                      backgroundImage: `url(${IMG_PATH + e.uri})`,
                    }}
                  >
                    <div className="close" onClick={() => onClickRemove(i, e)}>
                      <Icons.CloseX width="20" height="20" color="#3260AD" />
                    </div>
                  </div>
                )
              )
            )}
          {_countFile < maxLength && (
            <div className="input_upload">
              <div {...getRootProps({ className: 'box_upload' })}>
                <input {...getInputProps()} />
                <div className="show_svg">
                  <Icons.Image width="32" height="25" />
                </div>
                <div className="h1">อัพโหลดรูปภาพ</div>
              </div>
            </div>
          )}
        </div>
      </div>
      {errors && <div className="errors">{errors}</div>}
    </UploadSetImageStyled>
  );
};

UploadSetImage.propTypes = {};

export default UploadSetImage;
