export default function MenuHome() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.667"
      height="38.667"
      viewBox="0 0 38.667 38.667"
    >
      <g
        id="Group_280"
        data-name="Group 280"
        transform="translate(-121 -673.948)"
      >
        <path
          id="Path_681"
          data-name="Path 681"
          d="M2510.7,1237.208h0a19.333,19.333,0,1,1,.02-38.667h.014a19.348,19.348,0,0,1,19.3,19.336h0A19.358,19.358,0,0,1,2510.7,1237.208Zm.018-36.6a17.268,17.268,0,1,0-.02,34.536h0a17.268,17.268,0,0,0,.031-34.536Z"
          transform="translate(-2370.371 -524.593)"
          fill="#fff"
        />
        <path
          id="Path_682"
          data-name="Path 682"
          d="M2507.224,1227.938q0,1.72,0,3.438a1.185,1.185,0,0,0,1.266,1.264q1.15.006,2.3,0a1.176,1.176,0,0,0,1.264-1.26q0-2.883,0-5.767a.945.945,0,0,1,1.07-1.074q1.65,0,3.3,0a.935.935,0,0,1,1.06,1.053q0,2.883,0,5.767a1.187,1.187,0,0,0,1.275,1.283q.956,0,1.912,0a1.194,1.194,0,0,0,1.3-1.315q0-3.4,0-6.792a.92.92,0,0,1,1.007-1.02c.388-.007.777.006,1.164-.008a1.145,1.145,0,0,0,1.089-.724,1.1,1.1,0,0,0-.27-1.246q-4.639-5.15-9.271-10.3a1.313,1.313,0,0,0-2.178,0q-4.63,5.138-9.261,10.276a1.12,1.12,0,0,0-.288,1.267,1.161,1.161,0,0,0,1.143.726c.379.005.758,0,1.137,0a.923.923,0,0,1,.978,1q0,1.719,0,3.438"
          transform="translate(-2374.266 -528.366)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
