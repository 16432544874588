import styled from "styled-components";

export const CusActivityFormStyled = styled.div`
  margin-top: 20px;
  border-radius: 1rem;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .body_form_campaign {
    .input_type_wrap {
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      .itw_left_box {
        width: 20%;
        display: flex;
        align-items: center;
      }
      .itw_right_box {
        width: 40%;
      }
    }
    .input_wrap {
      margin-bottom: 20px;
      width: 100%;
      &.mt30 {
        margin-top: 30px;
      }
      .left_box {
        width: 20%;
        display: flex;
        align-items: center;
      }
      .right_box {
        width: 80%;
        display: flex;
        align-items: flex-start;
        .w50 {
          width: 50%;
        }
      }
    }
    .input_table_wrap {
      margin-bottom: 20px;
    }
    .array_item {
      margin-bottom: 1rem;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_1};
      border-radius: 0.5rem;
      padding: 1rem 1rem 0.1rem 1rem;
    }
    .btn_zone {
      margin: auto;
      margin-top: 50px;
      width: 160px;
    }
  }
`;
